import router from '@/router'
import store from '@/store'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// next() 放行
// next(false) 跳转终止
// next(地址) 跳转到某个地址
// 自定义白名单  没有token也可以访问的网页
const whiteList = ['/login','/404','/Gzhlogin']  
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (store.getters.token) {
    const userInfo = store.getters.userInfo
    localStorage.setItem('LULONG_USERINFO',JSON.stringify(userInfo))
    // 如果有token
          if (to.path === '/login') {
            // 如果访问的是登录页  就跳到主页
            next('/')
          } else {
              if (!store.getters.userId) {
                const { authorities } = await store.dispatch('user/getUserInfo')
                console.log(authorities);
                const routes = await store.dispatch('permission/setRoutes', authorities)
                console.log(routes);
               //添加动态路由到路由表
                routes.forEach(item => {
                  router.addRoute(item)
      
                })
                router.addRoute( {
                  path: '*',
                   redirect: '/404'
                 })
                next(to.path)
              } else {
                next()
            }
            
          }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
  NProgress.done()  //手动强制关闭  手动切换路由不触发路由后置守卫 进度条不能关闭
})

// 后置守卫
router.afterEach(() => {
  NProgress.done()
})