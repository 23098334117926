import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

// 引入dayjs格式化时间
import dayjs from 'dayjs' 
// 引入中文包
import 'dayjs/locale/zh-cn' 

Vue.prototype.$dayjs = dayjs 

Vue.prototype.$homesocket 
// 引入echarts
import * as echarts from 'echarts'
Vue.use(echarts)
Vue.prototype.$echarts = echarts;
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' //element-ui样式
Vue.use(ElementUI)
import JwChat from 'jwchat';
Vue.use(JwChat)
// 引入科技边框
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
//引入表格样式
import "@/assets/css/tablecss.css"
// 引用lib-flexible插件
import "lib-flexible"
// 引入百度地图
import BaiduMap from 'vue-baidu-map'
import jsPlumb from 'jsplumb'
Vue.prototype.$jsPlumb = jsPlumb.jsPlumb
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'MLhdSUDT9oFGn2ExzjqqiAGLmgPyIXbf' //'MLhdSUDT9oFGn2ExzjqqiAGLmgPyIXbf' // 'wiyGzaWUBi3fGLLZL9EfbuGx7Q6U7N0d'

})
import utils from './utils/index'
import auth from './utils/auth'
import './permission'

// import './utils/rem'

import socket from '@/utils/socket'
Vue.prototype.$socket = socket

Vue.prototype.$utils = utils
Vue.prototype.$auth = auth




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
