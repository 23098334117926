import Layout from '@/layout'
// 树状结构
export const tranListToTreeData = (arr, pid) => {
  const parent = arr.filter(item => item.parentId === pid)
  function findDeep(parent) {
    parent.forEach(p => {
      p.children = arr.filter(c => c.parentId === p.id)
      findDeep(p.children)
    })
  }
  findDeep(parent)
  return parent
}

// export const getRoutesList = (data,arr) => {
//   data.forEach((datas, index) => {
//     arr.push({
//       path: datas.path,
//       name: datas.name,
//       component: datas.component === 'Layout' ? Layout : () => import([`@/views/${datas.component}.vue`]),
//       meta: {
//         title: datas.title,
//         icon: datas.icon,
//       },
//       //  redirect: datas.redirect,
//       // 子路由
//       children: []
//     })
//     if (datas.children) {
//       const childArr = getRoutesList(datas.children, [])
//       arr[index].children = childArr
//     }
//   })
//   return arr
// }