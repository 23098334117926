<template>
  <div>
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
      <el-radio-button :label="false">展开</el-radio-button>
      <el-radio-button :label="true">收起</el-radio-button>
    </el-radio-group> -->
    <el-menu
      :router="true"
      :default-active="this.$route.path"
      class="el-menu-vertical-demo"
      text-color="#fff"
      active-text-color="#409EFF"
      unique-opened
      :collapse="$store.getters.isCollapse"
    >
      <sidebar-item v-for="(item, index) in routes" :key="index" :item="item" />
    </el-menu>
  </div>
</template>

<script>
import SidebarItem from "./SidebarItem.vue";
import { mapGetters } from "vuex";
export default {
  name: "LeftNav",
  components: {
    SidebarItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["routes"]),
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
  },

  methods: {},
};
</script>
<style lang="scss">
.el-menu--popup-right-start {
  background: linear-gradient(to right, #041725, #144364) !important;
}
</style>
<style lang="scss" scoped>
::v-deep .el-menu {
  background: linear-gradient(to right, #041725, #144364) !important;
  overflow-y: auto;
}

/* .el-menu-vertical-demo {
  height: calc(100vh-80px);
} */
::v-deep .el-submenu__title:hover {
  background-color: #fff !important;
  color: #409eff !important;
}

::v-deep .el-menu-item:hover {
  background-color: #fff !important;
  color: #409eff !important;
}
</style>
<style lang="scss" scoped>
::v-deep .el-submenu.is-active .el-submenu__title {
  color: red;
}

.el-menu-vertical-demo {
  height: 100vh;

  &:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  ::v-deep .el-menu-item-group__title {
    padding: 0;
  }

  ::v-deep .el-submenu.is-active .el-submenu__title {
    border-bottom: 1px solid gray;
    color: #409eff !important;
  }

  .el-menu-item.is-active {
    color: #409eff !important;
  }

  // 每个选项的
  ::v-deep .el-submenu__title {
    border-bottom: 1px solid gray;
  }

  ::v-deep.el-menu-item {
    background-color: #144364 !important;
    border-bottom: 1px solid gray;
  }
}
</style>
<style lang="scss">
.el-menu--popup-right-start {
  background: linear-gradient(to right, #041725, #144364) !important;
  .el-submenu__title:hover {
    color: #409eff !important;

  }
  .el-menu-item:hover {
    background-color: #fff !important;
    color: #409eff !important;
  }

}
</style>
