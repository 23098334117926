import axios from "axios";
import store from "../store";
import { Message } from "element-ui";
import router from "@/router";
import { getTimeStamp } from "./auth";
// token过期时长
const timeOut = 14400; //秒
// 定义判断过期函数
function checkTimeOut() {
  const currentTime = Date.now();
  const timestamp = getTimeStamp();
  const flag = (currentTime - timestamp) / 1000 > timeOut;
  return flag;
}

// 创建实例时配置默认值
// 当执行 npm run serve => .env.development => /api => 跨域代理
const instance = axios.create({
  // baseURL: "http://192.168.1.113:6660",
  baseURL: "https://java.beijingzhijiehulian.com",
  // baseURL: "http://192.168.1.113:6660",
  // baseURL: "http://26.6.111.157:6660",
  // baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 5000, //超时时间
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if (store.getters.token) {
      // console.log(store.getters.token);      
      if (checkTimeOut()) {
        store.dispatch("user/logout");
        router.push("/login");
        // return抛出一个执行错误, 用于终止promise的执行链
        return Promise.reject(new Error("token超时，请重新登录"));
      }
      // 配置请求头信息
      config.headers.token = store.getters.token;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // axios默认加了一层data
    const { code, errorMessage, data } = response.data;
    if (code == 200 || code == 501) {
      // Message.success(errorMessage);
      return data;
    }
    // else if (code == 500) {
    //   Message.error(errorMessage);
    // }
    //else if (code == 10000) {
    //   Message.error(errorMessage + ",请联系系统管理员");
    // } else if (code == 401) {
    //   Message.error(errorMessage);
    // } else if (code == undefined) {
    //   return data
    // }
    else {
      // 后端判断token过期使用
      // if (error && error.response && error.response.data.code === 10001) {
      //   store.dispatch("user/logout");
      //   router.push("/login");
      // }
      // else {
      //   Message.error(errorMessage);
      // }
      // Message.error(errorMessage);
      return Promise.reject(new Error(errorMessage));
    }
  },
  // return response
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (error.code == "ECONNABORTED") {
      Message.error("连接超时");
    } else if (error.code == "ERR_BAD_RESPONSE") {
      Message.error("非法token，请重新登陆");
      store.dispatch("user/logout");
      router.push("/login");
    } else {
      // console.log(error.message);
      Message.error(error.message);
    }
  }
);

export default instance;
